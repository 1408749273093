import SEO from '@bradgarropy/gatsby-plugin-seo'
import Post from 'components/Blog/Post/Main'
import TableOfContents from 'components/Blog/Post/Aside'
import { graphql } from 'gatsby'
import { FC } from 'react'

const PostTemplate: FC = ({ data }) => {
  const post = data.markdownRemark

  const { slug, title, date, topic, description } = post.frontmatter
  console.log({
    title,
    description
  })
  return (
    <>
      <SEO
        title={`${title} | Hacking the JS Interview`}
        description={description}
      />

      <div className="post-template">
        <Post post={post} />

        <TableOfContents />
      </div>
    </>
  )
}

export const postTemplateQuery = graphql`
  query ($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      frontmatter {
        slug
        title
        description
        date(formatString: "MMMM D, YYYY")
        topic {
          name
          icon
        }
      }
    }
  }
`

export default PostTemplate
