import { FC } from 'react'
import { useHeadingsData } from '../Aside/helpers'

import './Post.scss'

type PostProps = {
  post: {
    html: string
    frontmatter: {
      slug: string
      title: string
      date: string
    }
  }
}

const Post: FC<PostProps> = ({ post }) => {
  const { html, frontmatter } = post
  const { slug, title, date } = frontmatter

  const { nestedHeadings } = useHeadingsData('section h2, section h3')

  return (
    <article className="post">
      <h1>{title}</h1>

      {nestedHeadings && nestedHeadings.length > 0 && (
        <div>
          <div>Table of Contents</div>
          <ul>
            {nestedHeadings.map((heading) => (
              <li key={heading.id} className={''}>
                <a
                  href={`#${heading.id}`}
                  onClick={(e) => {
                    e.preventDefault()
                    document
                      .querySelector(`section a[href="#${heading.id}"]`)
                      .scrollIntoView({
                        behavior: 'smooth'
                      })
                  }}>
                  {heading.title}
                </a>
                {heading.items.length > 0 && (
                  <ul>
                    {heading.items.map((child) => (
                      <li key={child.id} className={''}>
                        <a
                          href={`#${child.id}`}
                          onClick={(e) => {
                            e.preventDefault()
                            document
                              .querySelector(`section #${child.id}`)
                              .scrollIntoView({
                                behavior: 'smooth'
                              })
                          }}>
                          {child.title}
                        </a>
                      </li>
                    ))}
                  </ul>
                )}
              </li>
            ))}
          </ul>
        </div>
      )}

      <section
        className="fancyLinks"
        dangerouslySetInnerHTML={{
          __html: html
        }}
      />
    </article>
  )
}

export default Post
