import { useState } from 'react'
import Headings from './Headings'
import { useHeadingsData, useIntersectionObserver } from './helpers'

import './TableOfContents.scss'

const TableOfContents = () => {
  const [activeId, setActiveId] = useState()

  const { nestedHeadings } = useHeadingsData()

  useIntersectionObserver(setActiveId)

  if (nestedHeadings && nestedHeadings.length > 0) {
    return (
      <nav className="myNavBar">
        <div>Table of Contents</div>
        <Headings headings={nestedHeadings} activeId={activeId} />
      </nav>
    )
  } else {
    return null
  }
}

export default TableOfContents
